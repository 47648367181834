
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Payment List</h5>
      <div class="row mb-3 gy-2">
        <div class="col-sm-12 col-md-6 d-flex">
          <div id="tickets-table_length" class="me-2 dataTables_length">
            <label class="d-inline-flex mb-0 align-items-center">
              <b-form-select class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            </label>
          </div>
          <div class="dropdown no-arrow me-2">
            <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="ri ri-filter-3-fill"></i>
            </a>
            <ul class="dropdown-menu">
              <li>
                <button @click="selectAllRows()" class="dropdown-item" type="button">
                  {{isSelectAll? 'Invert selection' : 'Select all' }}
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="pageData.data"
                :fields="columns" responsive="sm">
                  <template v-slot:cell(check)="data">
                    <div style="width:3rem;" class="form-check text-center me-auto">
                      <input v-model="selected" type="checkbox" :value="data.item.id" class="form-check-input me-2" :id="`tableSRadio${data.item.id}`" />
                      <label class="form-check-label" :for="`tableSRadio${data.item.id}`">{{data.item.id}}</label>
                    </div>
                  </template>
                  <template v-slot:cell(user_email)="data">
                    <div>
                      <span> {{data.item.user_email}} </span> <br/>
                      <span class="small"> {{data.item.paymentid}} </span>
                    </div>
                  </template>
                  <template v-slot:cell(paid_amount)="data">
                    <span> ${{data.item.paid_amount}} </span>
                  </template>
                  <template v-slot:cell(method)="data">
                    <div style="width:5rem;"><span>{{data.item.method}} </span> </div>
                  </template>
                  <template v-slot:cell(date)="data">
                    <div style="width:7rem;" class="cursor-pointer">{{$filters.date_ago(data.item.created_at) }} </div>
                  </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="7" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import IsLoading from "@/components/IsLoading.vue"
import _ from 'lodash';

export default {
  name: "payment-list",
  components:{
    IsLoading
  },
  data() {
    return {
      isLoading: false,
      pageData: {
        data:[]
      },
      searchQuery: "",
      pageOptions: [10, 25, 50, 100],
      selected: [],
      isSelectAll: false,
      columns: [
          {
              key: "check",
              label: "ID"
          },
          {
            key: "user_email",
            label: "User email",
            sortable: true
          },
          {
            key: "paid_amount",
            label: "Amount",
            sortable: true
          },
          {
            key: "method",
            label: "Method",
            sortable: true
          },
          {
            key: "currency",
            label: "Currency",
            sortable: true
          },
          {
            key: "date",
            label: "Date",
            sortable: true
          },
      ]
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
  },
  methods: {
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.pageData.data.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null) {
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$http.get(`/payments?${new URLSearchParams(payload).toString()}`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.pageData = response.data.data;
        }
      })
    },
  },
  created(){
    this.fetchItems()
  },
}

</script>

